import { UserData } from "./helpers/firebase.tsx";
import { useEffect, useState } from "react";
import { db } from "./helpers/firebase.tsx";
import { collection, getDocs } from "firebase/firestore";
import { Loading } from "./components/Loading.tsx";

export default function Admin() {
  const [users, setUsers] = useState<UserData[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    const fetchUsers = async () => {
      const querySnapshot = await getDocs(collection(db, "users"));
      const usersList = querySnapshot.docs.map((doc) => doc.data() as UserData);
      setUsers(
        usersList.filter((user) => !user.email?.endsWith("@massiverocket.com")),
      );
      setLoading(false);
    };

    fetchUsers();
  }, []);

  return (
    <div className="section">
      <div>
        {loading && <Loading />}
        {!loading && (
          <table className="table is-fullwidth">
            <thead>
              <tr>
                <th>Name</th>
                <th>Email</th>
                <th>Company Name</th>
                <th>Company Size</th>
              </tr>
            </thead>
            <tbody>
              {users.map((user, index) => (
                <tr key={index}>
                  <td>
                    {user.firstName} {user.lastName}
                  </td>
                  <td>
                    <a href={`mailto:${user.email}`}>{user.email}</a>
                  </td>
                  <td>{user.company}</td>
                  <td>{user.companySize}</td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
    </div>
  );
}
