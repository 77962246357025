import { useState, FormEvent, ChangeEvent, useContext } from "react";
import { doc, setDoc } from "firebase/firestore";
import { auth, db } from "../../helpers/firebase.tsx";
import { toast } from "react-toastify";
import { useNavigate } from "@tanstack/react-router";
import { AuthContext } from "../../helpers/authContext.tsx";

export function CompleteProfile() {
  const navigate = useNavigate();
  const { refreshUserData } = useContext(AuthContext);
  const [formData, setFormData] = useState({
    company: "",
    companySize: "",
  });
  const [updating, setUpdating] = useState<boolean>(false);

  const handleFormChange = (
    e: ChangeEvent<HTMLInputElement | HTMLSelectElement>,
  ) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmitProfile = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const { company, companySize } = formData;
    const user = auth.currentUser;

    if (user) {
      setUpdating(true);
      try {
        await setDoc(doc(db, "users", user.uid), {
          company,
          companySize,
          email: user.email,
          firstName: user.displayName?.split(" ")[0] || "",
          lastName: user.displayName?.split(" ")[1] || "",
          created_at: new Date(),
        });
        await refreshUserData();
        // wait a bit for the user data to be updated
        await new Promise((resolve) => setTimeout(resolve, 500));
        toast("Profile completed successfully!", { type: "success" });
        await navigate({ to: "/" });
      } catch (error) {
        const errorMessage = (error as Error).message;
        toast(`Error saving profile data: ${errorMessage}`, { type: "error" });
      } finally {
        setUpdating(false);
      }
    }
  };
  return (
    <div className="section">
      <div className="notification is-info">
        Complete your profile to proceed.
      </div>
      <div className="card">
        <div className="box">
          <header className="card-header">
            <p className="card-header-title">Complete Profile</p>
          </header>
          <div className="card-content">
            <form onSubmit={handleSubmitProfile}>
              <div className="columns">
                <div className="column is-half">
                  <div className="field">
                    <label className="label">Company</label>
                    <div className="control">
                      <input
                        className="input"
                        type="text"
                        name="company"
                        placeholder="Enter your company"
                        value={formData.company}
                        onChange={handleFormChange}
                        required
                      />
                    </div>
                  </div>
                </div>

                <div className="column is-half">
                  <div className="field">
                    <label className="label">Company Size</label>
                    <div className="select is-fullwidth">
                      <select
                        className="input"
                        name="companySize"
                        value={formData.companySize}
                        onChange={handleFormChange}
                        required
                      >
                        <option value="">Select company size</option>
                        <option value="1-10">1-10</option>
                        <option value="10-25">10-25</option>
                        <option value="25-50">25-50</option>
                        <option value="50-100">50-100</option>
                        <option value="100-200">100-200</option>
                        <option value="200-500">200-500</option>
                        <option value="500-1000">500-1000</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>

              <div className="field is-grouped">
                <p className="control">
                  <button
                    className="button is-primary"
                    type="submit"
                    disabled={updating}
                  >
                    <span className="icon">
                      <i className="fas fa-save"></i>
                    </span>
                    <span>Submit</span>
                  </button>
                </p>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
