import { getAuth, GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import { toast } from "react-toastify";
import { useNavigate } from "@tanstack/react-router";
import { useContext, useEffect } from "react";
import { AuthContext } from "../../helpers/authContext.tsx";

export function GoogleSignIn() {
  const navigate = useNavigate({ from: "/login" });
  const { user } = useContext(AuthContext);

  useEffect(() => {
    if (user) {
      void navigate({ to: "/" });
    }
  }, [user, navigate]);

  const onGoogleSignIn = async () => {
    const provider = new GoogleAuthProvider();
    const auth = getAuth();
    try {
      const result = await signInWithPopup(auth, provider);
      // wait a bit before navigating to ensure auth is updated
      await new Promise((resolve) => setTimeout(resolve, 500));
      toast(`Welcome ${result.user.displayName}`, {
        type: "success",
      });
    } catch {
      toast("Cannot sign you in for the moment. Please try again later.", {
        type: "error",
      });
    }
  };

  return (
    <button className="button is-dark mb-4" onClick={onGoogleSignIn}>
      <span className="icon">
        <i className="fab fa-google"></i>
      </span>
      <span>Sign in with Google</span>
    </button>
  );
}
